import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  
export const loginSchema = yup.object().shape({
  email: yup.string()
  // .email()
  .required("Email or mobile is required."),
  password: yup
    .string()
    .required("Password is required.")
    .min(8)
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must contain 8 characters, One uppercase, One lowercase, One number and One special Case Character."
    // ),
});
