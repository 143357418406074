import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
// loadibng components
import LoadingScreen from "./../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      element: <MainLayout />,
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "select-user-type",
          element: (
            <GuestGuard>
              <SelectUserType />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "forgot-password",
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "verify-otp",
          element: (
            <GuestGuard>
              <VerifyOtp />
            </GuestGuard>
          ),
        },
        //   { path: 'login-unprotected', element: <Login /> },
        //   { path: 'register-unprotected', element: <Register /> },
        //   { path: 'reset-password', element: <ResetPassword /> },
        //   { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Steps Routes
    {
      path: "supplier",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { path: "information", element: <SupplierInformation /> },
        { path: "kyc", element: <KnowYourCustomer /> },
        { path: "banking", element: <BankingInformation /> },
        { path: "verification", element: <SupplierVerification /> },
        { path: "documentation", element: <SupplierDocumentation /> },
        { path: "signature", element: <SupplierSignature /> },
      ],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <AppDashboard /> }],
    },
    {
      path: "user",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "profile", element: <Profile /> }],
    },
    // Main Routes
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <HomePage /> },
        { path: "about-us", element: <About /> },
        { path: "/step-completed", element: <StepsCompletedPage /> },
        { path: "/email-verification", element: <EmailVerification /> },
        { path: "contact-us", element: <Contact /> },
        // { path: "faqs", element: <Faqs /> },
        // {
        //   path: "components",
        //   children: [
        //     { element: <ComponentsOverview /> },
        //     // FOUNDATIONS
        //     { path: "color", element: <Color /> },
        //   ],
        // },
      ],
    },
    // { path: "*", element: <Navigate to="/404" replace /> },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// Main
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
// const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const SelectUserType = Loadable(lazy(() => import("../pages/auth/UserType")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const VerifyOtp = Loadable(lazy(() => import("../pages/auth/VerifyOtp")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const SupplierInformation = Loadable(
  lazy(() => import("../pages/suppliers/SupplierInformation"))
);
const KnowYourCustomer = Loadable(
  lazy(() => import("../pages/suppliers/KnowYourCustomer"))
);
const BankingInformation = Loadable(
  lazy(() => import("../pages/suppliers/BankingInformation"))
);
const SupplierVerification = Loadable(
  lazy(() => import("../pages/suppliers/Verification"))
);
const SupplierDocumentation = Loadable(
  lazy(() => import("../pages/suppliers/Documentation"))
);
const SupplierSignature = Loadable(
  lazy(() => import("../pages/suppliers/Signature"))
);
const StepsCompletedPage = Loadable(
  lazy(() => import("../components/StepsCompleted"))
);
const EmailVerification = Loadable(
  lazy(() => import("../components/EmailVerification"))
);

// Dashboard
const AppDashboard = Loadable(
  lazy(() => import("../pages/dashboard/Dashboard"))
);
//user routes
const Profile = Loadable(lazy(() => import("../pages/dashboard/Profile")));
