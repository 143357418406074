import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";

//hooks
import useAuth from "./../../../hooks/useAuth";
import { loginSchema } from "../../../Validations/LoginValidation";

//-------------------------------------------------

const LoginForm = () => {
  let navigate = useNavigate();
  const { pageRequest, login, isAuthenticated, isVerified, resendOtp, error } =
    useAuth();

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [showResendOtp, setShowResendOtp] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (error !== null && error !== undefined && error.success === false) {
      if (
        isVerified === false &&
        error !== null &&
        error.error == "Mobile number not verified."
      ) {
        Swal.fire({
          title: `${error.error}`,
          icon: "error",
          text: "Please verify your mobile number",
          showLoaderOnConfirm: true,
          preConfirm: async (login) => {
            await resendOtp({
              mobile_code: error.mobile_code,
              mobile_number: error.mobile_number,
            });
            navigate("/auth/verify-otp");
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `${error.error}`,
        });
        // pageRequest();
      }
      pageRequest();
    }
    if (isAuthenticated === true) {
      Swal.fire({
        icon: "success",
        title: `You are logged in successfully`,
        // text: "Thank You",
      });

      navigate("/dashboard");
    }
  }, [navigate, error, isVerified]);

  const handleLogin = async (data) => {
    await login(data);

    // if (
    //   isVerified === false &&
    //   error !== null &&
    //   error.error == "Mobile number not verified."
    // ) {
    //   Swal.fire({
    //     title: `${error.error}`,
    //     icon: "error",
    //     text: "Please verify your mobile number",
    //     showLoaderOnConfirm: true,
    //     preConfirm: async (login) => {
    //       await resendOtp({
    //         mobile_code: error.mobile_code,
    //         mobile_number: error.mobile_number,
    //       });
    //       navigate("/auth/verify-otp");
    //     },
    //   });
    // } else if (error !== null && error !== undefined) {
    //   Swal.fire({
    //     icon: "error",
    //     title: `${error.error}`,
    //   });
    // }
    // const isValid = await loginSchema.isValid(formData);
  };
  //   const LoginSchema = Yup.object().shape({
  //     email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //     password: Yup.string().required('Password is required')
  //   });
  return (
    <form onSubmit={handleSubmit(handleLogin)} className="user-form">
      <div className="col-lg-12 mb-4">
        <label htmlFor="email">
          Email <span className="req_input">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          placeholder="Enter Your Email/Mobile Number"
          name="email"
          {...register("email")}
        />
        <p className="text-danger">{errors.email?.message}</p>
      </div>
      <div className="col-lg-12 mb-4">
        <label htmlFor="password">
          Password <span className="req_input">*</span>
        </label>
        <div className="input-group">
          <input
            type={isRevealPwd ? "text" : "password"}
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
            placeholder="Enter Your Password"
            name="password"
            {...register("password")}
          />
          <button
            type="button"
            className="input-group-text"
            id="basic-addon1"
            title={isRevealPwd ? "Hide password" : "Show password"}
            onClick={() => setIsRevealPwd((prevState) => !prevState)}
          >
            {isRevealPwd === true ? (
              <FontAwesomeIcon icon={faEyeSlash} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faEye} size="lg" />
            )}
          </button>
        </div>
        <p className="text-danger">{errors.password?.message}</p>
      </div>
      <div className="col-lg-12 mt-3 mb-2">
        <div className="d-lg-flex justify-content-between">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="check"
            />
            <label className="form-check-label m-0" htmlFor="check">
              Remember Me
            </label>
          </div>
          <RouterLink to="/auth/forgot-password" className="text-primary">
            Forgot your password?
          </RouterLink>
        </div>
      </div>
      {showResendOtp && (
        <div className="col-lg-12 mb-2">
          <div className="d-lg-flex justify-content-center">
            <a href="reset_password.html" className="text-primary">
              <FontAwesomeIcon icon={faMobileAlt} /> Verify Your Mobile
            </a>
          </div>
        </div>
      )}
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-styleone">
          LOGIN
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
