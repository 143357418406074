// import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom/client";
// import 'font-awesome/css/font-awesome.min.css'

import { ToastContainer } from "react-toastify";

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import ScrollToTop from "./components/ScrollToTop";

// redux
import { store, persistor } from "./redux/store";

// contexts
import { AuthProvider } from "./contexts/JWTContext";

import App from "./App";

// import Custom Css
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "./assets/css/color.css";
import "./assets/css/responsive.css";
import "./assets/css/animate.min.css";

// import reportWebVitals from "./reportWebVitals";

// create a root
const root = ReactDOM.createRoot(document.getElementById("root"));

//render app to root
root.render(
  // <React.StrictMode>

  <HelmetProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop>
            <AuthProvider>
              <App />
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
            </AuthProvider>
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
