import React, { useContext } from "react";
import { Button, FormControl, InputGroup, Offcanvas } from "react-bootstrap";
import { NotificationContext } from "../../../contexts/NotificationContext";

import OffCanvasCard from "../OffCanvasCard/OffCanvasCard";
import { Link } from "react-router-dom";

export const NotificationShow = ({ onHideNotification, placement, showNotification }) => {
  const [notificationList, setNotificationList] = useContext(NotificationContext);

  const removeItem = (id) => {
    const removeThenNotificationItem = notificationList.filter(
      (listItem) => listItem?.id !== id
    );
    setNotificationList([...removeThenNotificationItem]);
    // console.log(removeThenNotificationItem);
  };
  return (
    <Offcanvas show={showNotification} onHide={onHideNotification} placement={placement}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="text-center mx-auto textwarning">
          Notification ({notificationList.length})
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {notificationList.map((item) =>
          item ? (
            <OffCanvasCard item={item} removeItem={removeItem} key={item.id} />
          ) : (
            ""
          )
        )}
        {notificationList.length > 0 ? (
          <Link to="/notification">
            <div className="text-center">
              <Button variant="outline-warning" className="btn-styleone">View Notifications</Button>
            </div>
          </Link>
        ) : (
          ""
        )}
        {notificationList.length <= 0 ? (
          <h6 className="text-center">No Item in the Notification List</h6>
        ) : (
          ""
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};