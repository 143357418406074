import * as yup from "yup";
// utils
import axios from "../utils/axios";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const registerSchema = yup.object().shape({
  first_name: yup.string().required("Please enter first name."),
  last_name: yup.string().required("Please enter last name."),
  // username: yup.string().required("Please enter username."),
  email: yup
    .string()
    .email("Email should be valid and contain @")
    .required("Please enter email.")
    .test(
      "Unique Email",
      "Email already in use", // <- key, message
      function (value) {
        return new Promise((resolve, reject) => {
          axios
            .post(`check-available-email`, { email: value })
            .then((res) => {
              resolve(true);
            })
            .catch((error) => {
              if (error.message === "The email has already been taken.") {
                resolve(false);
              }
            });
        });
      }
    ),
  // mobile: yup
  //   .string()
  //   .required("Please enter mobile number.")
  //   .matches(phoneRegExp, "Mobile number is not valid")
  //   .min(10, "to short")
  //   .max(10, "to long"),

  mobile: yup
    .string()
    .required("Please enter mobile number.")
    .matches(phoneRegExp, "Mobile number is not valid.")
    .test(
      "Unique Mobile Number",
      "Mobile number already in use", // <- key, message
      function (value) {
        const userMobile = localStorage.getItem('registerMobile');
        return new Promise((resolve, reject) => {
          axios
            .post(`check-available-mobile`, { mobile: userMobile })
            .then((res) => {
              resolve(true);
            })
            .catch((error) => {
              if (error.message === "The mobile has already been taken.") {
                resolve(false);
              }
            });
        });
      }
    ),
  // phone: yup.mixed().test("phone", "Error", value => {
  //   console.log(value)
  // }),
  // phone: yup.mixed().required("Please enter mobile number.").test("phone", "Error", value => {
  //   console.log(value)
  // }),
  // businessType: yup.string().required("Business type is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
