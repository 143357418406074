import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
//hooks
import useAuth from "./../../../hooks/useAuth";
import { registerSchema } from "../../../Validations/RegisterValidation";
//custom
// import IntlPhoneInput from "../../IntlPhoneInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./register.css";
//-------------------------------------------------

const RegisterForm = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { user_type } = state;

  const [countryData, setCountryData] = useState({});
  const [rawPhone, setRawPhone] = useState("");

  const {
    pageRequest,
    isLoading,
    register: userRegister,
    isRegistered,
    error,
  } = useAuth();

  // const [mobile, setMobile] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  // const { mobile, email, name, businessType, password, password_confirmation } = data;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(registerSchema),
  });

  // const onChange = (e) => {
  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleRegister = async (data) => {
    // console.log({ ...data, user_type, ...countryData, mobile: rawPhone }); return;
    await userRegister({
      ...data,
      user_type,
      ...countryData,
      mobile: rawPhone,
    });
    // const isValid = await loginSchema.isValid(formData);
  };
  useEffect(() => {
    if (error !== null && error !== undefined && error !== false) {
      if (
        typeof error === "object" &&
        !Array.isArray(error) &&
        error !== null
      ) {
        Swal.fire({
          icon: "error",
          // title: ,
          text: JSON.stringify(error.error),
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `${error.error}`,
        });
      }
    }
    if (isRegistered === true) {
      localStorage.removeItem("registerMobile");
      navigate("/auth/verify-otp");
    }

    pageRequest();
  }, [navigate, isRegistered, error, countryData]);

  if (countryData !== undefined) {
    // console.log(rawPhone, countryData)
    localStorage.setItem("registerMobile", rawPhone);
    // localStorage.setItem('registerMobileData', countryData);
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleRegister)} className="row">
        <div className="col-lg-6 mb-4">
          <label htmlFor="first_name">
            First Name <span className="req_input">*</span>
          </label>
          <input
            type="text"
            className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
            name="first_name"
            placeholder="E.g. Jhon"
            {...register("first_name")}
            // tabindex="1"
          />
          <p className="text-danger">{errors.first_name?.message}</p>
        </div>
        <div className="col-lg-6 mb-4">
          <label htmlFor="last_name">
            Last Name <span className="req_input">*</span>
          </label>
          <input
            type="text"
            className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
            name="last_name"
            placeholder="E.g. Doe"
            {...register("last_name")}
            // tabindex="2"
          />
          <p className="text-danger">{errors.last_name?.message}</p>
        </div>
        <div className="col-lg-12 mb-4">
          <label htmlFor="email">
            Email <span className="req_input">*</span>
          </label>
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            name="email"
            placeholder="E.g. name@gmail.com"
            {...register("email")}
            // tabindex="3"
          />
          <p className="text-danger">{errors.email?.message}</p>
        </div>
        <div className="col-lg-12 mb-4">
          <div className="telflag">
            <label htmlFor="mobile">
              Mobile Number <span className="req_input">*</span>
            </label>
            <Controller
              control={control}
              name="mobile"
              defaultValue=""
              render={({ field }) => (
                <PhoneInput
                  // tabIndex="4"
                  enableSearch={true}
                  countryCodeEditable={false}
                  className={`input-control ${
                    errors.mobile ? "is-invalid" : ""
                  }`}
                  inputRef={register}
                  country={"in"}
                  {...field}
                  error={!!errors.mobile}
                  inputStyle={{ width: "100%", minHeight: "45px" }}
                  searchClass="form-control"
                  // onChange={(value, data, e) => {
                  // setCountryData(data);
                  // setRawPhone(value.slice(data.dialCode.length))
                  //  }}
                  isValid={(value, country) => {
                    setCountryData(country);
                    setRawPhone(value.slice(country.dialCode.length));
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                />
              )}
            />
            <p className="text-danger">{errors.mobile?.message}</p>
          </div>
        </div>
        <div className="col-lg-12 mb-4">
          <label htmlFor="password">
            Password <span className="req_input">*</span>
          </label>
          <div className="input-group">
            <input
              type={isRevealPwd ? "text" : "password"}
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
              name="password"
              {...register("password")}
              placeholder="At least 8 characters"
              // tabindex="5"
            />
            <button
              type="button"
              className="input-group-text"
              id="basic-addon1"
              title={isRevealPwd ? "Hide password" : "Show password"}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
            >
              {isRevealPwd === true ? (
                <FontAwesomeIcon icon={faEyeSlash} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faEye} size="lg" />
              )}
            </button>
          </div>
          <p className="text-danger">{errors.password?.message}</p>
          {/* <div id="UPIHelp" className="form-text fs-12">
            Passwords must be at least 8 characters.
          </div> */}
        </div>
        <div className="col-lg-12">
          <label htmlFor="password_confirmation">
            Confirm Password <span className="req_input">*</span>
          </label>
          <div className="input-group">
            <input
              type={isRevealPwd ? "text" : "password"}
              className={`form-control ${
                errors.password_confirmation ? "is-invalid" : ""
              }`}
              name="password_confirmation"
              {...register("password_confirmation")}
              // tabindex="6"
            />
            <button
              type="button"
              className="input-group-text"
              id="basic-addon1"
              title={isRevealPwd ? "Hide password" : "Show password"}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
            >
              {isRevealPwd === true ? (
                <FontAwesomeIcon icon={faEyeSlash} size="1x" />
              ) : (
                <FontAwesomeIcon icon={faEye} size="1x" />
              )}
            </button>
          </div>
        </div>
        <p className="mt-3 fs-12">
          <span className="text-danger">*</span>We will send you a text to
          verify your phone and email.
        </p>

        {/* <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-12">
            <div className="col-lg-12 mb-4">
              <label htmlFor="password">
                Setup Password <span className="req_input">*</span>
              </label>
              <div className="input-group">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  className="form-control"
                  name="password"
                  {...register("password")}
                />
                <button
                  type="button"
                  className="input-group-text"
                  id="basic-addon1"
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                >
                  {isRevealPwd === true ? (
                    <FontAwesomeIcon icon={faEyeSlash} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faEye} size="lg" />
                  )}
                </button>
              </div>
              <p className="text-danger">{errors.password?.message}</p>
            </div>
            <div className="col-lg-12">
              <label>
                Confirm Password <span className="req_input">*</span>
              </label>
              <div className="input-group">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  className="form-control"
                  name="password_confirmation"
                  {...register("password_confirmation")}
                />
                <button
                  type="button"
                  className="input-group-text"
                  id="basic-addon1"
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                >
                  {isRevealPwd === true ? (
                    <FontAwesomeIcon icon={faEyeSlash} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faEye} size="1x" />
                  )}
                </button>
              </div>
              <p className="text-danger">
                {errors.password_confirmation?.message}
              </p>
            </div>
          </div>
        </div> */}
        <div className="signupstatus_btn">
          <div className="d-flex justify-content-between mt-4">
            <RouterLink
              to="/auth/select-user-type"
              className="btn btn-styletwo"
              type="button"
            >
              Prev
            </RouterLink>
            <button
              className="btn btn-styleone"
              type="submit"
              disabled={isLoading === true ? true : false}
            >
              {isLoading === true ? (
                <FontAwesomeIcon icon={faCircleNotch} spin size="lg" />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
