import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isInitialized: false,
  isRegistered: false,
  isVerified: false,
  user: null,
  success: false,
  message: null,
  error: null,
};

const handlers = {
  // START LOADING
  IS_LOADING(state) {
    return {
      ...state,
      isLoading: true
    };
  },
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isVerified: false,
      user,
    };
  },
  REQUESTSUCCESS: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isRegistered: false,
      isAuthenticated: false,
      isVerified: false,
      isSent: false,
      user: null,
      error: null,
    };
  },
  LOGIN: (state, action) => {
    const { user, success, error } = action.payload;
    // console.log("action.payload", error);
    if (user !== undefined) {
      return {
        ...state,
        isRegistered: false,
        isAuthenticated: true,
        user,
        error: null,
      };
    } else {
      // console.log("error", error);
      return {
        ...state,
        isRegistered: false,
        isAuthenticated: false,
        user: null,
        error,
      };
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user, success, error } = action.payload;
    if (user !== undefined) {
      return {
        ...state,
        // isAuthenticated: true,
        isLoading: false,
        isAuthenticated: false,
        isRegistered: true,
        user: null,
      };
    } else {
      // console.log("error", error);
      return {
        ...state,
        isLoading: false,
        isRegistered: false,
        isAuthenticated: false,
        isRegistered: false,
        user: null,
        error,
      };
    }
  },
  VERIFYEMAIL: (state, action) => {
    const { user, error, success, message } = action.payload;
    if (success) {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isVerified: true,
        success,
        message,
        error: null,
      };
    } else {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isVerified: false,
        user: null,
        message: null,
        error,
      };
    }
  },
  VERIFYOTP: (state, action) => {
    const { user, error, success, message } = action.payload;
    if (success) {
      return {
        ...state,
        isAuthenticated: false,
        isVerified: true,
        success,
        message,
        error: null,
      };
    } else {
      return {
        ...state,
        isAuthenticated: false,
        isVerified: false,
        user: null,
        message: null,
        error,
      };
    }
  },
  RESENDOTP: (state, action) => {
    const { success, message } = action.payload;
    if (success) {
      return {
        ...state,
        isAuthenticated: false,
        success,
        message,
      };
    } else {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        message,
      };
    }
  },
  FORGOTPASSWORD: (state, action) => {
    const { success, message, error } = action.payload;
    if (success) {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isSent: true,
        success,
        message,
      };
    } else {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error,
      };
    }
  },
  RESET_PASSWORD: (state, action) => {
    const { user, success, error } = action.payload;
    // console.log("action.payload", error);
    if (user !== undefined) {
      return {
        ...state,
        isLoading: false,
        isRegistered: false,
        isAuthenticated: true,
        user,
        error: null,
      };
    } else {
      // console.log("error", error);
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error,
      };
    }
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
  verifyOtp: () => Promise.resolve(),
  resendOtp: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/validateToken");
          const { user } = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // const login = async (email, password) => {
  const pageRequest = () => {
    dispatch({
      type: "REQUESTSUCCESS",
      payload: {
        error: null,
      },
    });
  };

  // const login = async (email, password) => {
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        "/login",
        // {
        //   email,
        //   password,
        // },
        formData,
        config
      );

      const { token, user } = response.data;
      // console.log(response.data.token);
      setSession(response.data.token);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (error) {
      // console.log("error", error);
      dispatch({
        type: "LOGIN",
        payload: {
          error: error,
        },
      });

      // setTimeout(
      //   dispatch({
      //     type: "LOGININITIALIZE",
      //     payload: {
      //       error: error,
      //     },
      //   }),
      //   2000
      // );
    }
  };

  const register = async (formData) => {
    // console.log("formData", formData);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dispatch({
      type: "IS_LOADING"
    });

    try {
      const response = await axios.post("/register", formData, config);
      // const { accessToken, user } = response.data;
      const { user, otp, error, success } = response.data;
      if (otp !== undefined) {
        window.localStorage.setItem("otpData", JSON.stringify(otp));
      }
      dispatch({
        type: "REGISTER",
        payload: {
          user,
        },
      });
    } catch (error) {
      // console.log("error", error);
      dispatch({
        type: "REGISTER",
        payload: {
          error: error,
        },
      });
    }
  };

  const verifyEmail = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING"
    });

    try {
      const response = await axios.post("/verify-email", formData, config);
      const { success, message } = response.data;
      if (success === true) {
        dispatch({
          type: "VERIFYEMAIL",
          payload: {
            success,
            message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "VERIFYEMAIL",
        payload: {
          error: error,
        },
      });
    }
  };

  const verifyOtp = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post("/verify-otp", formData, config);
      const { success, message } = response.data;
      if (success === true) {
        // window.localStorage.removeItem("otpData");
        dispatch({
          type: "VERIFYOTP",
          payload: {
            success,
            message,
          },
        });
      }
    } catch (error) {
      // console.log("error", error);
      dispatch({
        type: "VERIFYOTP",
        payload: {
          error: error,
        },
      });
    }
  };

  const resendOtp = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post("/resend-otp", formData, config);
      const { otp, success, message } = response.data;
      if (otp !== undefined) {
        window.localStorage.setItem("otpData", JSON.stringify(otp));
      }
      dispatch({
        type: "RESENDOTP",
        payload: {
          success,
          message,
        },
      });
    } catch (error) {
      // console.log("error", error);
      dispatch({
        type: "RESENDOTP",
        payload: {
          message: error.message,
        },
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  //forgot password
  const forgotPassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING"
    });
    try {
      const response = await axios.post("/forgot-password", formData, config);

      const { data, success, message } = response.data;
      dispatch({
        type: "FORGOTPASSWORD",
        payload: {
          success,
          message,
        },
      });
    } catch (error) {
      // console.log("error", error);
      dispatch({
        type: "FORGOTPASSWORD",
        payload: {
          error: error,
        },
      });
    }
  };

  const resetPassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({
        type: "IS_LOADING"
      });
      const response = await axios.post("/reset-password", formData, config);
      const { token, user } = response.data;
      setSession(response.data.token);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (error) {
      // console.log("error", error);
      dispatch({
        type: "RESET_PASSWORD",
        payload: {
          error: error,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        pageRequest,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        verifyEmail,
        verifyOtp,
        resendOtp
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
