import { useState } from "react";
import { Outlet } from "react-router-dom";
// hooks
// import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import TopHeader from "./TopHeader";
import Header from "./Header";
import Sidebar from "./Sidebar";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  return (
    <>
      {/* <TopHeader /> */}
      <Header />

      <section id="my-account_area" className="ptb-50">
        <div className="container">
          <div className="row">
            <Sidebar />
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="tab-content dashboard_content">
                <div className="tab-pane fade show active" id="dashboard">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
