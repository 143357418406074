import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  divisions: [],
  categories: [],
  countries: [],
  states: [],
  kycQuestions: [],
};

const slice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DIVISIONS
    getDivisionsSuccess(state, action) {
      state.isLoading = false;
      state.divisions = action.payload;
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },
    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },
    // GET STATES
    getStatesSuccess(state, action) {
      state.isLoading = false;
      state.states = action.payload;
    },
    // GET KYC QUESTIONS
    getKycQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.kycQuestions = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------
//divisions
export function getDivisions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-divisions");
      dispatch(slice.actions.getDivisionsSuccess(response.data.divisions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//categories
export function getCategories(divisionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-categories", { divisionId });
      dispatch(slice.actions.getCategoriesSuccess(response.data.categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//countries
export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-countries");
      dispatch(slice.actions.getCountriesSuccess(response.data.countries));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//states
export function getStates(countryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-states", { countryId });
      dispatch(slice.actions.getStatesSuccess(response.data.states));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get kyc questions
export function getKycQuestions(countryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-kyc-questions", { countryId });
      dispatch(slice.actions.getKycQuestionsSuccess(response.data.questions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}