export const MenuItems = [
  {
      name: "Home",
      href: "/",
      children: [
      ]
  },
  // {
  //   name: "Shop",
  //   href: "#!",
  //   mega_menu: true,
  //   children: [
  //     {
  //       name: "Shop Layouts",
  //       children: [
  //         {
  //           name: "Shop Four Grid",
  //           href: "/shop",
  //         },
  //         {
  //           name: "Shop Three Grid",
  //           href: "/shopTwo",
  //         },
  //         {
  //           name: "Shop List View",
  //           href: "/shoplist",
  //         },
  //         {
  //           name: "Shop Left Sidebar",
  //           href: "/shop-left-bar",
  //         },
  //         {
  //           name: "Shop Right Sidebar",
  //           href: "/shop-right-bar",
  //         },
  //         {
  //           name: "Product Single",
  //           href: "/product-details-one/1",
  //         },
  //         {
  //           name: "Product Single Two",
  //           href: "/product-details-two/1",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Other Pages",
  //       children: [
  //         {
  //           name: "Cart View One",
  //           href: "/cart",
  //         },
  //         {
  //           name: "Cart View Two",
  //           href: "/cartTwo",
  //         },
  //         {
  //           name: "Empty Cart",
  //           href: "/empty-cart",
  //         },
  //         {
  //           name: "Checkout View One",
  //           href: "/checkout-one",
  //         },
  //         {
  //           name: "Checkout View Two",
  //           href: "/checkout-two",
  //         },
  //         {
  //           name: "Wishlist",
  //           href: "/wishlist",
  //         },
  //         {
  //           name: "Compare",
  //           href: "/compare",
  //         },
  //         {
  //           name: "Order Tracking",
  //           href: "/order-tracking",
  //         },
  //         {
  //           name: "Order Complete",
  //           href: "/order-complete",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Services",
    href: "services",
    children: [
    ],
  },
  {
    name: "About Us",
    href: "about-us",
    children: [
    ],
  },
  // {
  //   name: "Contact",
  //   href: "#!",
  //   children: [
  //     {
  //       name: "Contact Us One",
  //       href: "/",
  //     },
  //     {
  //       name: "Contact Us Two",
  //       href: "/contact-us-2",
  //     }
  //   ],
  // },
  {
    name: "Contact Us",
    href: "contact-us",
    children: [
    ],
  },
];
