import * as React from "react";

export default function LoadingScreen() {
  return (
    <>
      <div className="loader_wrapper">
        <img
          width="200px"
          src={process.env.PUBLIC_URL + "/images/loader.gif"}
          alt="loader"
        />
      </div>
    </>
  );
}
