// routes
import Router from "./routes";
// components
// import NotistackProvider from "./components/NotistackProvider";
import { NotificationListProvider } from "./contexts/NotificationContext";
import { CartListProvider } from "./contexts/CartContext";
import { WishListProvider } from "./contexts/WishListContext";

function App() {
  return (
    <>
      {/* <NotistackProvider> */}
      <NotificationListProvider>
        <CartListProvider>
          <WishListProvider>
            <Router />
          </WishListProvider>
        </CartListProvider>
      </NotificationListProvider>
      {/* </NotistackProvider> */}
    </>
  );
}

export default App;
