import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

//--------------------------------------------------------------

const SocialLinks = () => {
  return (
    <ul className="social-link">
      <li>
        <a href="#!">
          <FontAwesomeIcon icon={faFacebook} size="lg" />
        </a>
      </li>
      <li>
        <a href="#!">
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </a>
      </li>
      <li>
        <a href="#!">
          <FontAwesomeIcon icon={faInstagram} size="lg" />
        </a>
      </li>
      <li>
        <a href="#!">
          <FontAwesomeIcon icon={faLinkedin} size="lg" />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
