import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// components
import Page from "../../components/Page";
import { LoginForm } from "../../components/auth/login";
import { UserTypeLeft } from "../../components/auth/register";
// sections

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  return (
    <Page title="Login">
      {/* <CommonBanner pageTitle="Login" /> */}

      <section id="login_area">
        <div className="container">
            <div className="row">
                <UserTypeLeft />
                <div className="col-lg-8">
                    <div className="signup_block login_block">
                        <div className="text-center mb-3 mt-4">
                            <h2 className="fs-24 mb-3 fancy_title">Login</h2>
                        </div>
                        <div className="steps_block">
                            <div className="row">

                                <div className="col-lg-6 offset-lg-3 col-md-12">
                                  <LoginForm />
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <div className="card_registersmo">
                                        <p>Don't have any account?<RouterLink to="/auth/select-user-type">Register here</RouterLink></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </Page>
  );
}
