import { useState, useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OTPInput, { ResendOTP } from "otp-input-react";
import Swal from "sweetalert2";

//hooks
import useAuth from "./../../../hooks/useAuth";
import { lastFourDigits } from "../../../utils/helpers";

//-------------------------------------------------

const VerifyOtp = () => {
  let navigate = useNavigate();
  const otpData = JSON.parse(window.localStorage.getItem("otpData"));
  const { verifyOtp, isVerified, resendOtp, message, error } = useAuth();

  const [mobileOTP, setMobileOTP] = useState("");
  const [fieldError, setFieldError] = useState("");

  // console.log(otpData);
  const handleVerify = async (event) => {
    event.preventDefault();
    if (mobileOTP == "" || mobileOTP.length < 4) {
      // Swal.fire({
      //   icon: "error",
      //   title: `Please enter otp`,
      //   // text: "Thank You",
      // });
      setFieldError("OTP field is required.");
      // return false;
    } else {
      setFieldError("");
    }

    await verifyOtp({
      mobileOTP,
      user_id: otpData.user_id,
      mobileCode: otpData.mobile_code,
      mobileNumber: otpData.mobile_no,
    });
    // const isValid = await loginSchema.isValid(formData);
  };
  useEffect(() => {
    if (error !== null && error !== undefined) {
      Swal.fire({
        icon: 'error',
        title: `${error.error}`,
      });
    }
    if (isVerified === true) {
      Swal.fire({
        icon: "success",
        title: `${message}`,
        // text: "Thank You",
      });
      navigate("/auth/login");
    }
  }, [message, isVerified, fieldError, error]);

  const resendMobileOTP = async () => {
    await resendOtp({
      mobile_code: otpData.mobile_code,
      mobile_number: otpData.mobile_no,
    });
  };
  const renderButton = (buttonProps) => {
    return (
      <button className="btn btn-sm btn-primary" {...buttonProps}>
        Resend
      </button>
    );
  };
  // console.log("otpData", otpData);
  return (
    <>
      <div className="steps_block">
        <div className="text-center mb-5">
          <h4 className="fs-20 fw-500">Enter OTP For Verification</h4>
          {otpData !== null && otpData !== undefined && (
            <p className="mt-4">
              Enter the code we just send on your mobile phone{" "}
              <span className="text-primary">
                {/* +{otpData.mobile_code}-{otpData.mobile_no} */}
                {lastFourDigits(`+${otpData.mobile_code}-${otpData.mobile_no}`)}
              </span>
              <p>
                <b>
                  Your pin is:{" "}
                  <em style={{ color: "tomato" }}>{otpData.otp}</em>
                </b>
              </p>
            </p>
          )}
        </div>

        <form onSubmit={handleVerify}>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12">
              <div className="otp_input d-flex flex-row mt-4">
                <OTPInput
                  value={mobileOTP}
                  onChange={setMobileOTP}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  secure={false}
                  inputStyles={{ width: "60px", height: "40px" }}
                />
              </div>
              {fieldError ? (
                <div className="otp_input d-flex flex-row text-danger">
                  {fieldError}
                </div>
              ) : null}

              <div className="login_submit mt-5 mb-3">
                <button
                  type={mobileOTP ? "submit" : "button"}
                  className="theme-btn-one btn-black-overlay btn_md"
                  disabled={mobileOTP ? false : true}
                  id="otp_signup_verify"
                >
                  Verify
                </button>
              </div>
              <p className="text-muted text-center">
                {/* Didn't receive the code? */}
                {/* <a href="#" className="text-primary ps-2">
                Resend
              </a> */}
                <ResendOTP
                  // maxTime={10}
                  renderButton={renderButton}
                  onResendClick={() => resendMobileOTP()}
                />
              </p>
            </div>
          </div>
        </form>

        {/* <div className="signupstatus_btn">
          <RouterLink to="/auth/register" className="btn btn-styletwo" type="submit">
            Prev
          </RouterLink>
        </div> */}
      </div>
    </>
  );
};

export default VerifyOtp;
