import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//hooks
import useAuth from "./../../hooks/useAuth";

// ----------------------------------------------------------------------

export default function Header() {
  const { isAuthenticated, user, logout } = useAuth();

  let navigate = useNavigate();
  const location = useLocation();
  let dispatch = useDispatch();

  const handleLogout = async () => {
    await logout();
    navigate("/auth/login");
  };
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-3">
        <div className="dashboard_tab_button">
          <ul role="tablist" className="nav flex-column dashboard-list">
            <li>
              <RouterLink
                to="/dashboard"
                className={
                  location.pathname === "/my-account" ? "active" : null
                }
              >
                <i className="fa fa-tachometer"></i>Dashboard
              </RouterLink>
            </li>
            <li>
              {" "}
              <RouterLink
                to="/my-account/customer-order"
                className={
                  location.pathname === "/my-account/customer-order"
                    ? "active"
                    : null
                }
              >
                <i className="fa fa-cart-arrow-down"></i>Orders
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to="/my-account/customer-download"
                className={
                  location.pathname === "/my-account/customer-download"
                    ? "active"
                    : null
                }
              >
                <i className="fa fa-cloud-download"></i>Downloads
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to="/my-account/customer-address"
                className={
                  location.pathname === "/my-account/customer-address"
                    ? "active"
                    : null
                }
              >
                <i className="fa fa-map-marker"></i>Addresses
              </RouterLink>
            </li>
            <li>
              <RouterLink
                to="/my-account/customer-account-details"
                className={
                  location.pathname === "/my-account/customer-account-details"
                    ? "active"
                    : null
                }
              >
                <i className="fa fa-user"></i>Account details
              </RouterLink>
            </li>
            {isAuthenticated ? (
              <li>
                <RouterLink
                  to="/#!"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                >
                  <i className="fa fa-sign-out"></i>Logout
                </RouterLink>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </>
  );
}
