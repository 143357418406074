import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
//------------------------------------------------------------------

const UserTypeRight = () => {
  const navigate = useNavigate();

  const schema = Yup;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSignup = async (data) => {
    // await login(data);
    // console.log("data", data); return;
    navigate("/auth/register", { state: { user_type: data.user_type } });
  };

  return (
    <>
      <div className="col-lg-8">
        <div className="signup_block">
          <div className="text-center mb-5">
            <h2 className="fs-24 mb-3 fancy_title">Select User Type</h2>
          </div>

          <div className="steps_block">
            {/* <div className="text-center mb-5">
              <h4 className="fs-18 fw-500">Select User Type</h4>
            </div> */}
            <form onSubmit={handleSubmit(handleSignup)}>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12">
                  <div className="box box-check">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="user_type"
                        // checked=""
                        value="customer"
                        {...register("user_type", { required: true })}
                      />
                      <b className="border-oncheck"></b>
                      <img
                        src={process.env.PUBLIC_URL + "/images/tx/man.png"}
                        className="mb-2"
                        alt=""
                      />
                      <span className="form-check-label"> Customer </span>
                    </label>
                  </div>
                  <div className="box box-check">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="user_type"
                        value="supplier"
                        {...register("user_type", { required: true })}
                      />
                      <b className="border-oncheck"></b>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/tx/delivery-man.png"
                        }
                        className="mb-2"
                        alt=""
                      />
                      <span className="form-check-label"> Supplier </span>
                    </label>
                  </div>
                  <div className="box box-check">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="user_type"
                        value="financier"
                        {...register("user_type", { required: true })}
                        disabled={true}
                      />
                      <b className="border-oncheck"></b>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/tx/businessman.png"
                        }
                        className="mb-2"
                        alt=""
                      />
                      <span className="form-check-label"> Financier </span>
                    </label>
                  </div>

                  <div className="text-danger mt-3">
                    {errors.user_type?.type === "required" &&
                      "Please select your user type."}
                  </div>
                </div>
              </div>
              <div className="signupstatus_btn">
                <div className="d-flex justify-content-end mt-4">
                  {/* <a
                    href="signup.html"
                    className="btn btn-styleone"
                    type="submit"
                  >
                    Next
                  </a> */}
                  <button type="submit" className="btn btn-styleone">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTypeRight;
