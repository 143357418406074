import React from "react";

const UserTypeLeft = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="signup_left">
          <h2 className="mb-4">WELCOME BACK!</h2>
          <h4 className="subtitle text-white fs-20 fw-500 lh-base">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod.
          </h4>

          <div className="quote_card">
            <ul className="m-0 p-0">
              <li>Lorem ipsum dolor sit amet, consectetur</li>
              <li>
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua.
              </li>
              <li>Lorem ipsum dolor sit amet, consectetur</li>
              <li>Lorem ipsum dolor sit amet, consectetur</li>
              <li>Lorem ipsum dolor sit amet, consectetur</li>
            </ul>
          </div>

          {/* <div className="login_txt">
            <p>
              Already have an account? <a href="login.html">Login</a>
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UserTypeLeft;
