import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
// import Img
// import banner from '../../../assets/img/common/nav_banner.png'

const NavItems = (props) => {
  return (
    <>
      {props.item.mega_menu ? (
        <li className="has-dropdown has-megaitem">
          <a href="#!">
            {props.item.name} <FontAwesomeIcon icon={faAngleDown} size="1x" />
          </a>
          <div className="mega-menu">
            <ul className="mega-menu-inner">
              {props.item.children.map((item, index) => (
                <li className="mega-menu-item" key={index}>
                  <p className="mega-menu-item-title">{item.name}</p>
                  <ul className="mega-menu-sub">
                    {item.children.map((datas, index) => (
                      <li key={index}>
                        <Link to={datas.href}>{datas.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              <li className="mega-menu-item">
                <div className="menu-banner">
                  <Link to="/shop" className="menu-banner-link">
                    <img
                      className="menu-banner-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/common/nav_banner.png"
                      }
                      alt="img"
                    />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </li>
      ) : (
        <>
          {props.item.children !== undefined ? (
            <li>
              <Link to={props.item.href} className="main-menu-link">
                {props.item.name}
              </Link>
            </li>
          ) : (
            <li className="has-dropdown">
              <a href="#!" className="main-menu-link">
                {props.item.name} <FontAwesomeIcon icon={faAngleDown} size="1x" />
              </a>
              <ul className="sub-menu">
                {props.item.children.map((data, index) => (
                  <li key={index}>
                    <Link to={data.href}>{data.name}</Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </>
      )}
    </>
  );
};

export default NavItems;
