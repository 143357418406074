// ----------------------------------------------------------------------

const lastFourDigits = (number) => {
  if (!number) {
    return false;
  }
  // ----------------------------------------------------------------------
  let string = String(number);
  let sliced = string.slice(-4);
  let mask = String(sliced).padStart(string.length, "x");
  return mask;
};

export { lastFourDigits };
