import React, { useState, createContext, useEffect } from "react";

export const NotificationContext = createContext();

export const NotificationListProvider = (props) => {
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    fetch("http://localhost:3000/shop.json")
      .then((response) => response.json())
      .then((data) => setNotificationList(data.slice(24, 28)));
  }, []);
// console.log('notificationList', notificationList)
  return (
    <NotificationContext.Provider value={[notificationList, setNotificationList]}>
      {props.children}
    </NotificationContext.Provider>
  );
};