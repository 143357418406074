import { Link as RouterLink, Outlet } from "react-router-dom";
// components
import Logo from "../components/Logo";

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <RouterLink to="/">
        {/* <Logo /> */}
      </RouterLink>
      <Outlet />
    </>
  );
}
