import React, { useContext, useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCartShopping,
  faBars,
  faSearch,
  faSignIn,
  faSignOut,
  faAngleDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart as farHeart,
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons";
// import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../components/Logo";
// hooks
import useAuth from "../../hooks/useAuth";
// components
// //
import { MenuItems } from "./MenuItems";
import NavItems from "./NavItems";
// import MenuDesktop from './MenuDesktop';
// import MenuMobile from './MenuMobile';
// import navConfig from './MenuConfig';
import logoWhite from "../../assets/img/logo-white.png";
import svg from "../../assets/img/svg/cancel.svg";
import svgsearch from "../../assets/img/svg/search.svg";
import { NotificationContext } from "../../contexts/NotificationContext";
import { WishListContext } from "../../contexts/WishListContext";
import { CartContext } from "../../contexts/CartContext";
import {
  CartShow,
  NotificationShow,
  WishListShow,
} from "../../components/OffCanvas";
import OffCanvas from "../../components/OffCanvas/OffCanvasSearch/OffCanvas";
import SocialLinks from "../SocialLinks";

import "./../Header.css";

// ----------------------------------------------------------------------

export default function Header() {
  const { isAuthenticated, user, logout } = useAuth();
  let navigate = useNavigate();
  const [click, setClick] = useState(false);

  // this hooks use search btn show
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // this hooks use notification show btn
  const [showNotification, setShowNotification] = useState(false);

  const handleClosNotification = () => setShowNotification(false);
  const handleShowNotification = () => setShowNotification(true);
  // this hooks use Shoping cart show btn
  const [showCart, setShowCart] = useState(false);

  const handleClosCart = () => setShowCart(false);
  const handleShowCart = () => setShowCart(true);

  // this hooks use wishlist show btn
  const [showWishList, setShowWishList] = useState(false);

  const handleClosWishLIst = () => setShowWishList(false);
  const handleShowWishList = () => setShowWishList(true);

  const [notificationList, setNotification] = useContext(NotificationContext);
  const [wishList, setWishList] = useContext(WishListContext);
  const [cartList, setCartList] = useContext(CartContext);

  const handleSearch = () => {
    if (click) {
      document.querySelector("#search").style =
        "transform: translate(-100%, 0); opacity: 0";
    } else {
      document.querySelector("#search").style =
        "transform: translate(0px, 0px); opacity: 1";
    }
    setClick(!click);
  };
  const handleabout = () => {
    if (click) {
      document.querySelector("#offcanvas-about").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#offcanvas-about").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };
  const handleMenu = () => {
    if (click) {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#mobile-menu-offcanvas").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };

  const handleShow = (value) => {
    value === show ? setShow("") : setShow(value);
  };

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const handleLogout = async () => {
    Swal.fire({
      icon: "success",
      title: "Logout Sucessfull",
      text: "Thank You",
    });
    await logout();
    navigate("/auth/login");
  };

  return (
    <>
      <header className="header-section d-none d-xl-block">
        <div className="header-wrapper">
          <div className="header-bottom header-bottom-color--golden section-fluid sticky-header sticky-color--golden">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="header-logo">
                    <div className="logo">
                      <RouterLink to="/">
                        {/* <img src={logo} alt="logo" /> */}
                        <Logo />
                      </RouterLink>
                    </div>
                  </div>
                  <div className="main-menu menu-color--black menu-hover-color--golden d-none d-xl-block">
                    <nav>
                      <ul>
                        {MenuItems.map((item, index) => (
                          <NavItems item={item} key={index} />
                        ))}
                      </ul>
                    </nav>
                  </div>

                  <ul className="header-action-link action-color--black action-hover-color--golden">
                    {isAuthenticated ? (
                      <>
                        <li>
                          <Button
                            variant=""
                            className="offcanvas-toggle"
                            onClick={handleShowNotification}
                          >
                            <FontAwesomeIcon icon={faBell} size="1x" />
                            <span className="position-absolute translate-middle badge rounded-pill p-1">
                              {notificationList.length}
                              <span className="visually-hidden">
                                unread messages
                              </span>
                            </span>
                          </Button>
                        </li>
                        <li>
                          {wishList.length ? (
                            <Button
                              variant=""
                              className="offcanvas-toggle"
                              onClick={handleShowWishList}
                            >
                              <FontAwesomeIcon icon={farHeart} size="1x" />
                              <span className="position-absolute translate-middle badge rounded-pill p-1">
                                {wishList.length}
                                <span className="visually-hidden">
                                  unread messages
                                </span>
                              </span>
                            </Button>
                          ) : (
                            <Button
                              variant=""
                              className="offcanvas-toggle"
                              onClick={handleShowWishList}
                            >
                              <FontAwesomeIcon icon={farHeart} size="1x" />
                              <span className="position-absolute translate-middle badge rounded-pill p-1">
                                {wishList.length}
                                <span className="visually-hidden">
                                  unread messages
                                </span>
                              </span>
                            </Button>
                          )}
                        </li>
                        <li>
                          {cartList.length ? (
                            <Button
                              variant=""
                              className="offcanvas-toggle"
                              onClick={handleShowCart}
                            >
                              <FontAwesomeIcon
                                icon={faCartShopping}
                                size="1x"
                              />
                              <span className="position-absolute translate-middle badge rounded-pill p-1">
                                {cartList.length}
                                <span className="visually-hidden">
                                  unread messages
                                </span>
                              </span>
                            </Button>
                          ) : (
                            <Button variant="" className="offcanvas-toggle">
                              <FontAwesomeIcon
                                icon={faCartShopping}
                                size="1x"
                              />
                              <span className="position-absolute translate-middle badge rounded-pill p-1">
                                {cartList.length}
                                <span className="visually-hidden">
                                  unread messages
                                </span>
                              </span>
                            </Button>
                          )}
                        </li>

                        <li>
                          {/* <RouterLink to="/dashboard">
                            <FontAwesomeIcon icon={faUserCircle} size="sm" />{" "}
                            {user.first_name || "Jhon Doe"}
                          </RouterLink> */}
                          <div className="dropdown">
                            <RouterLink to="/dashboard" className="dropbtn">
                              <FontAwesomeIcon icon={faUserCircle} size="sm" />{" "}
                              {user.first_name || "User"}{" "}
                              <FontAwesomeIcon icon={faAngleDown} size="sm" />
                            </RouterLink>
                            <div className="dropdown-content">
                              <RouterLink to="/user/profile">
                                <FontAwesomeIcon icon={faUser} size="sm" />{" "}
                                Profile
                              </RouterLink>
                              {/* <RouterLink to="#!"
                            onClick={() => {
                              handleLogout();
                            }}>
                              <FontAwesomeIcon icon={faSignOut} size="1x" />{" "}
                              Logout</RouterLink> */}
                            </div>
                          </div>
                        </li>
                        <li>
                          <RouterLink
                            to="#!"
                            onClick={() => {
                              handleLogout();
                            }}
                          >
                            <FontAwesomeIcon icon={faSignOut} size="1x" />{" "}
                            Logout
                          </RouterLink>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <a
                            href="#search"
                            className="search_width"
                            onClick={handleSearch}
                          >
                            <FontAwesomeIcon icon={faSearch} size="1x" />
                          </a>
                        </li>
                        <li>
                          <RouterLink
                            to="/auth/login"
                            className="offacnvas offside-about offcanvas-toggle"
                          >
                            <FontAwesomeIcon icon={faUserCircle} size="lg" />
                          </RouterLink>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-header sticky-header sticky-color--golden mobile-header-bg-color--golden section-fluid d-lg-block d-xl-none">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="mobile-header-left">
                <ul className="mobile-menu-logo">
                  <li>
                    <RouterLink to="/">
                      <div className="logo">
                        <Logo />
                      </div>
                    </RouterLink>
                  </li>
                </ul>
              </div>

              <div className="mobile-right-side">
                <ul className="header-action-link action-color--black action-hover-color--golden">
                  <li>
                    <a
                      href="#search"
                      className="search_width"
                      onClick={handleSearch}
                    >
                      <FontAwesomeIcon icon={faSearch} size="sm" />
                    </a>
                  </li>
                  <li>
                    <Button
                      variant=""
                      className="offcanvas-toggle"
                      onClick={handleShowNotification}
                    >
                      <FontAwesomeIcon icon={faBell} size="sm" />
                      <span className="position-absolute translate-middle badge rounded-pill p-1">
                        {notificationList.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </Button>
                  </li>
                  <li>
                    {wishList.length ? (
                      <Button
                        variant=""
                        className="offcanvas-toggle"
                        onClick={handleShowWishList}
                      >
                        <FontAwesomeIcon icon={farHeart} size="sm" />
                        <span className="position-absolute translate-middle badge rounded-pill p-1">
                          {wishList.length}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </Button>
                    ) : (
                      <Button
                        variant=""
                        className="offcanvas-toggle"
                        onClick={handleShowWishList}
                      >
                        <FontAwesomeIcon icon={farHeart} size="sm" />
                        <span className="position-absolute translate-middle badge rounded-pill p-1">
                          0
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </Button>
                    )}
                  </li>
                  <li>
                    {cartList.length ? (
                      <Button
                        variant=""
                        className="offcanvas-toggle"
                        onClick={handleShowCart}
                      >
                        <FontAwesomeIcon icon={faCartShopping} size="sm" />
                        <span className="position-absolute translate-middle badge rounded-pill p-1">
                          {cartList.length}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </Button>
                    ) : (
                      <Button variant="" className="offcanvas-toggle">
                        <FontAwesomeIcon icon={faCartShopping} size="sm" />
                        <span className="translate-middle badge rounded-pill p-1">
                          0
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </Button>
                    )}
                  </li>
                  <li>
                    <a
                      href="#!"
                      className="offcanvas-toggle offside-menu"
                      onClick={handleMenu}
                    >
                      <FontAwesomeIcon icon={faBars} size="sm" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="mobile-menu-offcanvas"
        className="offcanvas offcanvas-rightside offcanvas-mobile-menu-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleMenu}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="offcanvas-mobile-menu-wrapper">
          <div className="mobile-menu-bottom">
            <div className="offcanvas-menu">
              <ul>
                <li>
                  <a href="#!" onClick={() => handleShow("shop")}>
                    <span>Shop</span>
                  </a>
                  {show === "shop" ? (
                    <>
                      <ul className="mobile-sub-menu">
                        <li>
                          <a href="#!">Shop Layout</a>
                          <ul className="mobile-sub-menu">
                            <li>
                              <RouterLink to="/shop">Shop Four Grid</RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/shopTwo">
                                Shop Three Grid
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/shoplist">
                                Shop List View
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/shop-left-bar">
                                Shop Left Sidebar
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/shop-right-bar">
                                Shop Right Sidebar
                              </RouterLink>
                            </li>
                          </ul>
                        </li>
                      </ul>

                      <ul className="mobile-sub-menu">
                        <li>
                          <a href="#!">Shop Pages</a>
                          <ul className="mobile-sub-menu">
                            <li>
                              <RouterLink to="/cart">Cart View One</RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/cartTwo">
                                Cart View Two{" "}
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/empty-cart">
                                Empty Cart
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/checkout-one">
                                Checkout View One
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/checkout-two">
                                Checkout View Two
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/wishlist">Wishlist</RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/compare">Compare</RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/order-tracking">
                                Order Tracking
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/order-complete">
                                Order Complete
                              </RouterLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="mobile-sub-menu">
                        <li>
                          <a href="#!">Product Single</a>
                          <ul className="mobile-sub-menu">
                            <li>
                              <RouterLink to="/product-details-one/1">
                                Product Single
                              </RouterLink>
                            </li>
                            <li>
                              <RouterLink to="/product-details-two/1">
                                Product Single Two
                              </RouterLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </>
                  ) : null}
                </li>
                <li>
                  <a href="#!" onClick={() => handleShow("feature")}>
                    <span>Feature</span>
                  </a>
                  {show === "feature" ? (
                    <ul className="mobile-sub-menu">
                      <li>
                        <RouterLink to="/product-hover">
                          Product Hover
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink to="/order-success">
                          Order Success
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink to="/email-template-one">
                          Email Template 1
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink to="/email-template-two">
                          Email Template 2
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink to="/email-template-three">
                          Email Template 3
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink to="/lookbooks">LookBook</RouterLink>
                      </li>
                      <li>
                        <RouterLink to="/invoice-one">Invoice 1</RouterLink>
                      </li>
                      <li>
                        <RouterLink to="/invoice-two">Invoice 2</RouterLink>
                      </li>
                    </ul>
                  ) : null}
                </li>
                {isAuthenticated ? (
                  <>
                    <li>
                      <RouterLink
                        to="/dashboard"
                        onClick={() => {
                          handleMenu();
                        }}
                      >
                        <FontAwesomeIcon icon={faUserCircle} size="1x" />{" "}
                        Dashboard
                      </RouterLink>
                    </li>
                    <li>
                      <RouterLink
                        to="#!"
                        onClick={() => {
                          handleLogout();
                          handleMenu();
                        }}
                      >
                        <FontAwesomeIcon icon={faSignOut} size="1x" /> Logout
                      </RouterLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <RouterLink
                        to="/auth/login"
                        onClick={() => {
                          handleMenu();
                        }}
                      >
                        <FontAwesomeIcon icon={faSignIn} size="sm" /> Login
                      </RouterLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="mobile-contact-info">
            <div className="logo">
              <RouterLink to="/">
                <img src={logoWhite} alt="img" />
              </RouterLink>
            </div>
            <address className="address">
              <span>Address: Your address goes here.</span>
              <span>Call Us: 0123456789, 0123456789</span>
              <span>Email: demo@example.com</span>
            </address>
            <SocialLinks />

            <ul className="user-link">
              <li>
                <RouterLink to="/wishlist">Wishlist</RouterLink>
              </li>
              <li>
                <RouterLink to="/cart">Cart</RouterLink>
              </li>
              <li>
                <RouterLink to="/checkout-one">Checkout</RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        id="offcanvas-about"
        className="offcanvas offcanvas-rightside offcanvas-mobile-about-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleabout}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="mobile-contact-info">
          <address className="address">
            <img src={logoWhite} alt="logo" />
            <span>Address: Your address goes here.</span>
            <span>Call Us: 0123456789, 0123456789</span>
            <span>Email: demo@example.com</span>
          </address>
          <ul className="social-link">
            <li>
              <a href="#!">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#!">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#!">
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#!">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
          <ul className="user-link">
            <li>
              <RouterLink to="/wishlist">Wishlist</RouterLink>
            </li>
            <li>
              <RouterLink to="/cart">Cart</RouterLink>
            </li>
            <li>
              <RouterLink to="/checkout-one">Checkout</RouterLink>
            </li>
          </ul>
        </div>
      </div>

      {/* this is offcanvas section */}

      {/* <OffCanvas show={show} onHide={handleClose} placement={"start"} /> */}

      <NotificationShow
        showNotification={showNotification}
        onHideNotification={handleClosNotification}
        placement={"end"}
      />

      <CartShow
        showCart={showCart}
        onHideCart={handleClosCart}
        placement={"end"}
      />

      <WishListShow
        wishList={wishList}
        showWishList={showWishList}
        handleClosWishLIst={handleClosWishLIst}
        placement={"end"}
      />

      <div id="search" className="search-modal">
        <button type="button" className="close" onClick={handleSearch}>
          <img src={svg} alt="icon" />
        </button>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
            Swal.fire("Success", "Check out the Results", "success");
            navigate("/shop");
          }}
        >
          <input type="search" placeholder="type keyword(s) here" required />
          <button type="submit" className="btn btn-lg btn-main-search">
            Search
          </button>
        </form>
      </div>
    </>
  );
}
